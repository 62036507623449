/* Note: use only to override very specific theme styles. 
All other css rules should be written in their own respective sass documents. */

.custom-image {
  width: 600px;
}

.team-member {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  margin-bottom: 80px;
}

.team-member-bio {
  text-align: justify;
}


.responsive-content-section--background-image {
  position: relative;
}

.background-image_circles {
  position: absolute;
  top: -40px;
  left: 0;
  background-image: url('../img/background-image_circles.svg');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100% + 40px);
  opacity: 0.6;
  background-blend-mode: multiply;
}

.decorative-element_container {
  position: relative;
  width: 100%;
  height: 500px;
}

/* CUSTOM STYLIZED HEADER */
.custom-header--primary-light {
  font-family: 'Rubik';
  color: #0183BF;
  font-weight: 500;
}

.custom-header--primary {
  color: #01528A;
}